<template>
  <div class="answerQuestionsDetail">
    <div class="box">
      <section>
        <div class="icon" style="" @click="goback">
          <img src="../../assets/images/circleBack.svg" alt="" style="width: 14px;height: 14px">
          <span style=" margin-left: 5px;font-size: 14px;color: #409EFF;line-height: 15px">返回上一页</span>
        </div>

        <div class="title">
          <span class="title-c" style="color:#333333;font-weight:600;font-size: 20px;"> {{ questionDetail.title === null ? '暂无标题' : questionDetail.title }}</span>
          <header>
            <span>{{ questionDetail.isAnonymous===0?questionDetail.studentName:'匿名提问' }}</span>
            <span>{{ questionLabel[questionDetail.label].label }}</span>
          </header>
        </div>
        <div class="time">{{ questionDetail.createTime }}</div>
        <div>
          <div class="p_content" v-html="questionDetail.content" />
        </div>

        <div class="answer-c">
          <div v-if="replyList.length>0" class="reply-c">
            <div v-for="(item) in replyList" :key="item.id" class="reply-every">

              <div v-if="item.type===0" class="answer-name" style="color: #07479D;padding-top: 8px;">
                学生回复：
              </div>
              <div v-if="item.type===1" class="answer-name" style="color: #894306;padding-top: 8px;">
                老师回复：
              </div>
              <div class="answer-p" v-html=" item.content" />
              <!--              <Editor class="reply-e"  style="width: 90%" :readOnly=true :data="{type:6}" :value="item.content" v-model="item.content"></Editor>-->
            </div>
          </div>

          <div v-else class="reply-c" style="padding: 10px">
            暂无回复哦~
          </div>
        </div>

        <div class="changePage">
          <el-pagination
            background
            :current-page.sync="currentPage"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
        <div v-if="this.studentId===questionDetail.studentId" style="overflow: hidden">
          <Editor v-model="textarea" :height="250" style="width: 100%" :data="{type:6}" :value="textarea" />
          <div style="float: right;margin: 10px 20px">
            <el-button type="primary" size="mini" @click="replyToSave">回复</el-button>
          </div>
        </div>
      </section>
    </div>
    <el-dialog
      center
      title="回复信息"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div style=" margin-bottom: 10px;">
        回复信息
      </div>
      <div>
        <el-input
          v-model="textarea"
          type="textarea"
          :rows="7"
          maxlength="500"
          :show-word-limit="true"
          placeholder="请输入内容"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" round @click="replyToSave">提交</el-button>
      </span>
    </el-dialog>
    <indexBottom />
  </div>
</template>

<script>
import indexBottom from '../../components/indexBottom'
import Editor from '../../components/Editor'
import { getQuestionDetail, getReplyList, saveReply } from '../../api/answer/index'
import { mapState } from 'vuex'
export default {
  name: 'AnswerQuestionsDetail',
  components: {
    indexBottom, Editor
  },
  created() {
    this.questionId = this.$route.query.id
    this.studentId = this.$store.state.user.userInfo.id
    this.onLoad()
    this.getReply()
  },
  computed: {
    ...mapState({
      questionLabel: state => state.dict.dictTree.question_label['arr']
    })
  },
  data() {
    return {
      questionId: '',
      studentId: '',
      questionDetail: '',
      replyList: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,

      dialogVisible: false,
      textarea: ''

    }
  },
  methods: {
    onLoad() {
      getQuestionDetail({ id: this.questionId }).then(res => {
        console.log(res)
        this.questionDetail = res.data
      })
    },
    // 获取评论
    getReply() {
      const obj = {
        id: this.questionId,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      getReplyList(obj).then(res => {
        console.log(res)
        this.replyList = res.rows
        this.total = res.total
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getReply()
    },
    goback() {
      this.$router.go(-1)
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 展示弹框
    dialogShow() {
      this.dialogVisible = true
    },
    // 提交
    replyToSave() {
      if (this.textarea === '') {
        this.$message.error('请填写回复内容')
        return
      }
      const obj = {
        queryId: this.questionId,
        content: this.textarea
      }
      saveReply(obj).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.$message.success('回复成功')
          this.dialogVisible = false
          this.textarea = ''
          this.getReply()
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.box {
  margin-top: 80px;
}
.answerQuestionsDetail /deep/ .el-dialog {
  min-height: 350px;
}

.text-right {
  margin: 20px 0;
}

.icon {
  margin-bottom: 10px;
  cursor: pointer;
  display:flex;
  width: 100px;
  height: 20px;
  //border-bottom: 1px solid #E7ECF2;
  padding-bottom: 20px;
}

section {
  font-size: 18px;
  color: rgba(48, 48, 48, 1);
  text-align: left;
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  background: white;

  .title {
    margin-bottom: 10px;
    font-size: 18px;

    header {
      float: right;

      span {
        font-size: 14px;
        color: #aaa;
        margin-right: 20px;
      }

      span:nth-of-type(2) {
        color: #66b1ff;
        padding: 5px;
        border: 2px solid #409EFF;
        border-radius: 6px;
      }
    }
  }

  .time {
    color: #aaa;
    font-size: 14px;
    padding-bottom: 10px;
    border-bottom: 2px solid #E7ECF2;
  }

  div {
    font-size: 16px;
  }

  .p_content{
    //height: 200px;
    padding: 10px;
  }
  p {
    margin: 20px 0;
  }
}
.el-icon-arrow-left{
  cursor: pointer;
}
.reply-c{
  margin: 10px;
  min-height: 100px;
  .reply-every{
    width: 100%;
    border-bottom: 1px solid #f1f2f6;
    word-break: break-word;
    display: flex;
  }
  .answer-name{
    font-family:"Microsoft YaHei";
    margin: 12px 14px 0;
    font-size: 14px;
    width: 70px;
  }
  .answer-p{
    width: 950px;
    color: #717171;
    font-size: 14px;
    margin: 20px 0;
  }
}
.changePage{
  margin: 20px;
  text-align: right;
}
.answer-c{
  background-color: #f6f9fe;
}
.textButton:hover{
  color: #FFFFFF;
  background-color: #39A5EF;
}
//富文本的样式问题
 .answer-c {
   /deep/.ql-toolbar {
    display: none !important;
  }
  /deep/ .editor{
    border: 0;
  }
}

</style>
